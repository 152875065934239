.flexContainer {
    display: flex;
}
  
.flexContainerItem {
    flex: 1;
    word-break: break-all;
}

.linkBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        width: 49%;
    }
}

.fieldbox-mobile {
    display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
  
	> * {
	  width: 45%;
	  margin-bottom: 20px;
	}
}

.bottom-padding {
    padding-bottom: 62px;
}

@media only screen and (max-width: 992px) {
    .linkBox {
        flex-direction: column;
  
        > * {
            width: 100%;
        }
    }
}

$base-text-color: rgba(0, 0, 0, 0.87);
$base-accent: #03a9f4;

$side-panel-min-width: 60px;

/** Header */
$header-background-color: #00314c;
$header-text-color: #fff;
$header-icons-color: #fff;

/** Other */
$selected-item-color: #00314c;

/** SideNavigation */
$sidenav-background-color: #363640;
$sidenav-text-color: #fff;
$sidenav-border-color: #515159;
$sidenav-border-radius: 4px;
$sidenav-item-hover-background-color: #3f3f4b;
$sidenav-selected-menu-item-textcolor: #03a9f4;

/** RepairOrder */
$repairOrder-card-default-color: #fff;
$repairOrder-card-open-color: #fda619;
$repairOrder-card-accepted-color: #01a00c;
$repairOrder-card-declined-color: #a00101;
$repairOrder-card-paused-color: #b800b1;
$repairOrder-card-working-color: #007dc1;

/** Additional */
$default-button-background: #00314c;
$default-button-color: #fff;

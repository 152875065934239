@import "../../variables.scss";

/** Override default stylings */
.dx-button {
  border-radius: 10px !important;
}

.dx-selectbox-popup-wrapper .dx-list-item {
  margin: 0 !important;
}

.dx-fieldset {
  margin: 0 0 15px 0 !important;
}

.dx-field {
  flex-direction: column;

  .dx-field-label {
    width: unset;
    float: unset;
    align-self: unset !important;
  }
  
  .dx-field-value {
    width: unset !important;
  }
}

fieldset {
  margin: unset !important;
  padding: unset !important;
  border: unset !important;
}

/** Custom stylings */

.fieldbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    width: 45%;
    margin-bottom: 20px;
  }
}

.input-with-button {
  display: flex;

  .dx-textbox {
    flex: 1;
  }

  .text-input {
    flex: 1;
    line-height: 36px;
  }
}

@media only screen and (max-width: 992px) {
  .fieldbox {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
}

@import 'assets/styles/forms.scss';
@import 'assets/styles/responsive.scss';
@import 'assets/styles/ui_components.scss';
@import 'themes/generated/variables.base.scss';
@import 'variables.scss';

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;
  padding: 0 !important;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.form-block {
  margin: 0 auto;
  max-width: 900px !important;
}

.responsive-paddings {
  padding: 15px;

  .screen-large & {
    padding: 30px;
  }
}

.dx-inkripple > .dx-inkripple-wave {
  transition-property: none;
  transform: none;
  animation: none;
}

.dreieck {
  transition: 0.3s ease-in-out;
}

.rotated180 {
  transform: rotate(180deg);
}

.dx-fa-button.dx-fa-button-main {
  .dx-overlay-content,
  .dx-overlay-content:hover {
    background-color: $default-button-background;
  }
}

.card {
  background: #fff;
  border: 1px solid #eee;
  padding: 14px 16px;

  p {
    margin: 0;
  }
}

.card-detail {
  cursor: pointer;
  margin-bottom: 10px;
}

.no-margin-p {
  p {
    margin: 0;
  }
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.fullWidth {
  width: 100%;
}

.bss-hr {
  border: none;
  border-top: 1px dashed #ccc;
}

/** Override Devextreme Components styles */
.dx-popup-title {
	.dx-toolbar-label {
		.dx-item-content {
			padding-left: 20px;
		}
	}

	.dx-toolbar-button {
		.dx-item-content {
			padding-right: 10px;

			.dx-button {
				background: transparent;

				.dx-icon {
					color: white;
					width: 32px;
					height: 32px;
					font-size: 32px;
				}
			}
		}
	}
}

.dx-popup-wrapper {
	z-index: 9999 !important;
}

.dx-list-next-button {
	.dx-button {
		background-color: white !important;
		color: black !important;
	}
}

.swal2-title {
	font-size: 2rem !important;
	font-weight: bold !important;
}

.swal2-container {
	z-index: 10000 !important;

	.swal2-header {
		.swal2-title {
			font-size: 1.3rem !important;
			font-weight: bold;
		}
	}
	.swal2-actions {
		.swal2-styled {
			background-color: rgb(255, 255, 255) !important;
			color: #808080 !important;
			box-shadow: 0 0 4px #00000054 !important;
			width: auto;
		}
	}
}

/** Custom ui components */

.bss-alert {
    display: block;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.bss-alert-danger {
    color: #721c24;
    background-color: #f8d7da;
}

.bss-alert-warning {
    color: #856404;
    background-color: #fff3cd;
}

.default-button {
    background: transparent;
}

.block-button {
	display: block;
	width: 100%;
	max-width: 200px;
}

.bss-button {
	border: none;
	border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
	text-align: center;
}

.primary-button {
	background: #00314C;
	color: white;
}

.secondary-button {
	background: #00314C;
	color: white;
}
